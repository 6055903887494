<template>
    <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="checkout-setting-form">
        <div class="content_area">
            <div class="content_wpr">
                <div class="section_content w-100">
                    <div class="section_header">
                        <h2>Emails</h2>
                        <div class="tutorial_btn" @click="tutorialVideo"><label>Tutorials</label><img src="@/assets/images/play.svg"></div>
                        <!-- <button type="button" class="video_btn setting-video-btn" @click="tutorialVideo"><i class="fas fa-play"></i></button> -->
                    </div>
                    <div class="mt-1 p-0 border-0">
                        <div class="setting_wpr">
                            <ul class="mail_types">
                                <li :class="{ 'show': tab == 1 }">
                                    <h4 @click="tab = (tab == 1 ? 0 : 1)">Email 1 (13 minutes after abandon cart) <span class="status" :style="`color: ${firstAbandoned.enabled ? '#2f7eed' : '#999'};`">{{ firstAbandoned.enabled ? 'On' : 'Off'}}</span><i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <!-- <p class="note mb-4">Email 1 (13 minutes after abandon cart)</p> -->
                                            <div class="capsule_elm mt-2 mb-4">
                                                <h5>Notification</h5>
                                                <label for="firstAbandoned" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="firstAbandoned" :true-value="1" :false-value="0" v-model="firstAbandoned.enabled" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <email-component v-if="isMounted" v-model="firstAbandoned" :errors="errors" :handle-default-email="handleAbandonedFirstEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 2 }">
                                    <h4  @click="tab = (tab == 2 ? 0 : 2)">Email 2 (24 hours after abandon cart) <span class="status" :style="`color: ${secondAbandoned.enabled ? '#2f7eed' : '#999'};`">{{ secondAbandoned.enabled ? 'On' : 'Off'}}</span><i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <!-- <p class="note mb-4">Email 2 (24 hours after abandon cart)</p> -->
                                            <div class="capsule_elm mt-2 mb-4">
                                                <h5>Notification</h5>
                                                <label for="secondAbandoned" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="secondAbandoned" :true-value="1" :false-value="0" v-model="secondAbandoned.enabled" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <email-component v-if="isMounted" v-model="secondAbandoned" :errors="errors" :handle-default-email="handleAbandonedSecondEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 3 }">
                                    <h4  @click="tab = (tab == 3 ? 0 : 3)">Email 3 (48 hours after abandon cart) <span class="status" :style="`color: ${thirdAbandoned.enabled ? '#2f7eed' : '#999'};`">{{ thirdAbandoned.enabled ? 'On' : 'Off'}}</span><i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <!-- <p class="note mb-4">Email 3 (48 hours after abandon cart)</p> -->
                                            <div class="capsule_elm mt-2 mb-4">
                                                <h5>Notification</h5>
                                                <label for="thirdAbandoned" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="thirdAbandoned" :true-value="1" :false-value="0" v-model="thirdAbandoned.enabled" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <email-component v-if="isMounted" v-model="thirdAbandoned" :errors="errors" :handle-default-email="handleAbandonedThirdEmail" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click="$parent.$parent.closeModal()">Cancel</button>
            <button :disabled="loader" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
        </div>
    </Form>
</template>

<script>
    import { Form } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Abandoned Email Setting',

        data () {
            return {
                firstAbandoned: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 1,
                    email_logo: '',
                    enabled: 1,
                },
                secondAbandoned: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 1,
                    email_logo: '',
                    enabled: 1,
                },
                thirdAbandoned: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 1,
                    email_logo: '',
                    enabled: 1,
                },
                isMounted: false,
                tab: 0,
            }
        },

        props: {
            updateAbandonedEmail: {
                type: Function,
                default: () => {}
            },
            tutorialVideo : Function,
        },

        components: {
            Form,
        },

        watch: {
            abandonedEmailSettings (setting) {
                const vm = this;

                vm.resetForm();
            },

            tab (tab) {
                const vm = this;

                if (tab == 1) {
                    vm.updateAbandonedEmail(vm.firstAbandoned);
                } else if (tab == 2) {
                    vm.updateAbandonedEmail(vm.secondAbandoned);
                } else if (tab == 3) {
                    vm.updateAbandonedEmail(vm.thirdAbandoned);
                }
            },

            firstAbandoned: {
                handler (firstAbandoned) {
                    const vm = this;

                    vm.updateAbandonedEmail(firstAbandoned);
                },
                deep: true,
            },

            secondAbandoned: {
                handler (secondAbandoned) {
                    const vm = this;

                    vm.updateAbandonedEmail(secondAbandoned);
                },
                deep: true,
            },

            thirdAbandoned: {
                handler (thirdAbandoned) {
                    const vm = this;

                    vm.updateAbandonedEmail(thirdAbandoned);
                },
                deep: true,
            },
        },

        computed: mapState({
            abandonedEmailSettings: state => state.checkoutModule.abandonedEmailSettings,
            emailSettingLoader: state => state.checkoutModule.emailSettingLoader,
            loader: state => state.checkoutModule.updateEmailSettingLoader,
        }),

        mounted () {
            const vm = this;

            if (!Object.keys(vm.abandonedEmailSettings).length) {
                vm.getAbandonedEmailSettings({type: 1});
            }

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAbandonedEmailSettings: 'checkoutModule/getEmailSettings',
                updateEmailSettings: 'checkoutModule/updateEmailSettings'
            }),

            resetForm () {
                const vm = this;

                if (vm.abandonedEmailSettings && vm.abandonedEmailSettings.length) {
                    const emailSettings = JSON.parse(JSON.stringify(vm.abandonedEmailSettings));

                    emailSettings.forEach(({ step }, index) => {
                        if (step == 1) {
                            vm.firstAbandoned = emailSettings[index];
                            vm.firstAbandoned.defaultHandler = true;
                        } else if (step == 2) {
                            vm.secondAbandoned = emailSettings[index];
                            vm.secondAbandoned.defaultHandler = true;
                        } else if (step == 3) {
                            vm.thirdAbandoned = emailSettings[index];
                            vm.thirdAbandoned.defaultHandler = true;
                        }
                    });

                    vm.isMounted = true;
                }
            },

            handleAbandonedFirstEmail () {
                const vm = this;
                vm.firstAbandoned.subject = 'Oops, {{first_name}}! Did you forget something?';
                vm.firstAbandoned.email   = `<p>Hi {{first_name}},</p>
                                            <p>We noticed you were interested in our {{product_name}} program but didn\'t complete the checkout process.</p>
                                            <p>No worries, it happens to the best of us! To make things easy, we\'ve saved your spot and kept your {{product_name}} in the cart.</p>
                                            <p>Just click the button below to pick up where you left off: </p>
                                            <p>{{complete_purchase_button}}</p>
                                            <p>Don\'t miss out on the chance to transform your health and well-being.</p>
                                            <p>Remember, your journey to a healthier, happier you starts with a single step.</p>
                                            <p>Looking forward to seeing you on the other side,</p>
                                            <p>{{company_name}}</p>`;
            },

            handleAbandonedSecondEmail () {
                const vm = this;
                vm.secondAbandoned.subject = '{{first_name}}, don\'t miss out on {{product_name}}!';
                vm.secondAbandoned.email   = `<p>Hi {{first_name}},</p>
                                              <p>Just a friendly reminder that your {{product_name}} is still waiting for you in the cart.</p>
                                              <p>We know life gets busy, but don\'t let this opportunity slip away!</p>
                                              <p>{{complete_purchase_button}}</p>
                                              <p>Our {{product_name}} program has already helped countless people achieve their goals - and you could be next!</p>
                                              <p>But time is running out, so act now before it\'s too late.</p>
                                              <p>To your health,</p>
                                              <p>{{company_name}}</p>`;
            },

            handleAbandonedThirdEmail () {
                const vm = this;
                vm.thirdAbandoned.subject = 'Last chance, {{first_name}}! Your {{product_name}} is about to expire';
                vm.thirdAbandoned.email   = `<p>Hi {{first_name}},</p>
                                            <p>This is your final reminder that your {{product_name}} is still in the cart and about to expire.</p>
                                            <p>We don\'t want you to miss out on this life-changing program!</p>
                                            <p>{{complete_purchase_button}}</p>
                                            <p>Take action now and invest in your health.</p>
                                            <p>Remember, your greatest wealth is your good health. :-)</p>
                                            <p>Don\'t let this opportunity pass you by.</p>
                                            <p>Wishing you the best in your wellness journey,</p>
                                            <p>{{company_name}}</p>`;
            },

            handleSubmit () {
                const vm = this;

                const form = [vm.firstAbandoned, vm.secondAbandoned, vm.thirdAbandoned];

                vm.updateEmailSettings(form).then((result) => {
                    if (result) {
                        vm.getAbandonedEmailSettings({type: 1});
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .section_header .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .checkout-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .mail_types  {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .mail_types li  {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .mail_types li h4  {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
    }

    .mail_types li h4 i  {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show h4 i  {
        transform: rotate(-180deg);
    }

    .mail_types li .accordion_area  {
        max-height: 0;
        padding: 0 30px;
        height: 100%;
        border-top: 1px solid #e9e9e9;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show .accordion_area  {
        max-height: 1000vh;
        height: 100%;
        overflow-y: scroll;
    }

    .mail_types li.show .accordion_area::-webkit-scrollbar {
        display: none;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    :deep(p.note p) {
        margin: 10px 0;
    }

    .setting_wpr .mail_types li h4 .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
        margin-right: 10px;
    }

    .capsule_elm {
        padding: 0 30px;
    }

    @media(max-width: 599px){
        .mail_types li h4  {
            font-size: 13px;
            line-height: 18px;
            padding: 15px 20px;
        }
        .mail_types li .accordion_area{
            padding: 0 15px;
        }
        p.note {
            padding: 10px 15px;
            font-size: 11px;
            line-height: 18px;
        }
    }
</style>
